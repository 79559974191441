/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
// @import "~@ionic/angular/css/padding.css";
// @import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
// @import "~@ionic/angular/css/text-transformation.css";
// @import "~@ionic/angular/css/flex-utils.css";
@import "fonts.css";
@import "./theme/variables.scss";

ion-content {
  --ion-background-color: var(--ion-color-background);
}

@media (prefers-color-scheme: dark) {
  ion-content {
    --ion-background-color: #000000;
  }
}

ion-text, p, ion-label {
  font-size: 16px;
  margin-top: 0;
  color: var(--ion-color-light-contrast);
  line-height: initial;
}

ion-card-title {
  font-size: 22px;
}

a {
  color: var(--ion-color-secondary);
  text-decoration: underline;
}

h3 {
  font-size: 20px;
  margin-top: 0;
}

h4 {
  font-size: 18px;
}

.mat-icon, .icon-inner {
  color: var(--ion-color-tertiary);
}

ion-label {
  padding-right: 1em;
}

ion-card {
  background-color: var(--ion-color-medium-dark-background);
  @media screen and (min-width: $width-min-md) {
      margin-left: auto;
      margin-right: auto;
  }
}

ion-icon {
  vertical-align: middle;
  height: 1.1em;
}

ion-button {
  background-color: var(--ion-button);
  font-size: 0.9em;
  text-transform: none;
}

mat-expansion-panel {
  margin: 0 10px 0 10px !important;
  margin-bottom: 1px !important;
}

ion-title {
  .ios & {
    @media screen and (min-width: $width-min-md) {
      display: none;
    }
  }
}

.title-bar {
  height: 2em;
  display: table-cell;
  vertical-align: middle;
  display: flex;
}

.title-bar__padding {
  padding-left: 1em;
  padding-right: 1em;
}

.title {
  padding: 0;
  flex: 0.95;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-family: 'Russo One', sans-serif;

  @media screen and (min-width: $width-min-lg) {
    flex: inherit;
  }
}

.logo {
  max-width: 30%;
  margin-left: auto;
  height: 2em;

  @media screen and (min-width: $width-min-lg) {
    margin-left: 0.5em;
  }  
}

.mat-card {
  padding: 12px !important;
}

.searchbar-input.sc-ion-searchbar-md {
  border-radius: 20px;
  background-position: left 8px center;
  font-size: 1.1rem;
  font-weight: 400;
  background-color: white;
}

.mat-expansion-panel {
  background-color: var(--ion-color-light)
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: var(--ion-color-tertiary);
}

ion-card-header, .mat-expansion-panel-header {
  padding: 1em !important;
}

.mat-expansion-panel-header {
  background-color: var(--ion-color-light);
  margin-bottom: 1px;
}

.mat-expansion-panel-content {
  background-color: var(--ion-color-light);
}

.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]),
.mat-expansion-panel-header.mat-expanded:focus {
  background-color: var(--ion-color-light) !important;
}

.mat-expansion-panel-header {
  font-size: unset;
  height: 40px !important;
}

.mat-expansion-panel-body {
  padding: 0 1em 1em 1em !important;
}

.card-content-md p {
  font-size: unset;
  line-height: unset;
  padding-bottom: 0.4em;
}

/*
 * ion alerts styling
 */
.alert-message::-webkit-scrollbar, .alert-checkbox-group::-webkit-scrollbar {
  width: 0.3em;
  display: block !important;
}

.alert-message::-webkit-scrollbar-track, .alert-checkbox-group::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.alert-message::-webkit-scrollbar-thumb, .alert-checkbox-group::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 25px;
}

.alert {
  .alert-head {
    background: var(--ion-color-tertiary);
    margin-bottom: 1em;
    padding-top: 1em;
    padding-bottom: 1em;
    h2 {
      color: white;
    }
  }
  .alert-message {
    margin-bottom: 1em;
    padding-bottom: 0;
  }
  .alert-button-group.sc-ion-alert-md {
    padding-top: 0;
    padding-bottom: 0;
  }
}

ion-card-content {
  padding-right: 1em !important;
  padding-left: 1em !important;
}

ion-card {
  margin-top: 1em !important;
  margin-bottom: 0em !important;
}

@media (min-width: $width-min-lg) {
  .list-md {
    padding-top: 0;
  }

  ion-card, ion-list {
    max-width: 1500px;
    margin-left: auto !important;
    margin-right: auto !important;
    height: fit-content;
  }
}
